<template>
    <div>

        <form @submit.prevent="search">
            <div>

                <div class="card">
                    <div class="card-header" v-b-toggle.church>Принадлежность</div>
                    <b-collapse id="church" class="card-body row">
                        <div class="form-group col-md-6">
                            <label class="label">Страна</label>
                            <auto-complete
                                    url="/countries?name="
                                    @selected="countrySelected"
                                    :initialValue="country_id"
                                    :initialDisplay="country"
                                    @clear="clearCountry"
                            ></auto-complete>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="label">Город</label>
                            <auto-complete
                                    :url="findCityUrl()"
                                    @selected="citySelected"
                                    :initialValue="city_id"
                                    :initialDisplay="city"
                                    :disabled="!country_id"
                                    @clear="clearCity"

                            ></auto-complete>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="label">Церковь (Община)</label>

                            <v-select v-model="churchObj" :options="churches"></v-select>

                        </div>
                        <div class="form-group col-md-6">
                            <label class="label">Служение</label>

                            <v-select v-model="ministryObj" :options="ministries"></v-select>

                        </div>
                    </b-collapse>

                </div>
                <div class="card">
                    <div class="card-header " v-b-toggle.participient>Участие в ретрите</div>
                    <b-collapse class="card-body row" id="participient">
                        <div class="col-md-3 form-group">
                            <label class="label">Уч. ретрита</label>
                            <select
                                    class="form-control"
                                    v-model="isParticipient"
                            >
                                <option value="-" selected>Не важно</option>
                                <option value="1">Да</option>
                                <option value="0">Нет</option>
                            </select>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="label">Предв.рег</label>
                            <select
                                    :disabled="this.isParticipient !== '1'"
                                    class="form-control"
                                    v-model="isPrereg"
                            >
                                <option value="-" selected>Не важно</option>
                                <option value="1">Да</option>
                                <option value="0">Нет</option>
                            </select>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="label">Прибытие</label>
                            <select
                                    class="form-control"
                                    v-model="isArrived"
                                    :disabled="this.isParticipient !== '1'"
                            >
                                <option value="-" selected>Не важно</option>
                                <option value="1">Да</option>
                                <option value="0">Нет</option>
                            </select>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="label">Поселение</label>
                            <select
                                    class="form-control"
                                    v-model="isAccomodate"
                                    :disabled="this.isParticipient !== '1'"
                            >
                                <option value="-" selected>Не важно</option>
                                <option value="1">Да</option>
                                <option value="0">Нет</option>
                            </select>
                        </div>
                    </b-collapse>
                </div>
                <div class="card">
                    <div class="card-header ">Личные данные</div>
                    <div class="card-body row">
                        <div class="form-group col-md-6">
                            <label class="label">Ф.И.О. (Любая часть)</label>
                            <input
                                    autocomplete="off"
                                    v-model="fullname"
                                    class="form-control"
                                    ref="fullname"
                            />
                        </div>
                        <div class="form-group col-md-2 ">
                            <label class="label">Пол</label>
                            <select
                                    class="form-control"
                                    v-model="sex"
                            >
                                <option value="-">Любой</option>
                                <option value="m">М</option>
                                <option value="w">Ж</option>
                            </select>
                        </div>
                        <div class="col-md-4 form-group">
                            <h6 class="label" >Возраст</h6>

                            <label class="label">От</label>
                            <input type="number" v-model.number="ageFrom" min=0 class="age"/>

                            <label class="label">До</label>
                            <input type="number" v-model.number="ageTo" min=0 class="age"/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="label">Мобильный</label>
                            <input type="number" class="form-control" autocomplete="off" v-model.number="mobile"/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="label">email</label>
                            <input type="email" class="form-control" autocomplete="off" v-model.trim="email"/>
                        </div>

                    </div>
                </div>
                <div class="card">
                    <div class="card-footer ">
                        <button class="btn btn-secondary float-left" type="reset" @click="cancel">Отмена</button>
                        <button class="btn btn-primary float-right" :disabled="isSearching" type="submit">Поиск</button>
                    </div>
                </div>

            </div>
        </form>
        <div class="row" v-if="found.length > 0">
            <div class="col-md-10 offset-md-1">
                <table class="table table-stripped">
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>UID</th>
                        <th>Ф.И.О.</th>
                        <th>Пол</th>
                        <th>Д.рожд</th>
                        <th>Телефон</th>
                        <th>Церковь</th>
                        <th>Служение</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                            v-for="(item, index) in found"
                            :key="item.uid"
                            class="hover"
                            :data-uid="item.uid"
                            @click.prevent="go"
                    >
                        <td v-html="index+1"></td>
                        <td>
                            <router-link
                                    :to="{name: 'User', params: {uid: item.uid}}"
                                    target='_blank'
                            >
                                {{item.uid}}
                            </router-link>
                        </td>
                        <td v-html="item.fullname"></td>
                        <td v-html="item.sex"></td>
                        <td>{{item.birthday}} ({{item.age}})</td>
                        <td>{{item.mobile}}</td>
                        <td>{{item.country}}, {{item.city}}, {{item.church}}</td>
                        <td>{{item.ministry}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
  import AutoComplete from '../user/AutoComplete';
  import vSelect from 'vue-select';
  import moment from 'moment';

  export default {
    data: function () {
      return {
        country_id: null,
        city_id: null,
        church_id: null,
        country: null,
        city: null,
        church: null,
        ministry: null,
        ministry_id: null,
        isParticipient: '-',
        isArrived: '-',
        sex: '-',
        isAccomodate: '-',
        isPrereg: '-',
        ageFrom: 0,
        ageTo: 0,
        mobile: '',
        email: '',
        fullname: '',
        found: [],
        isSearching: false,
        ministries: [],
        churches: []
      };
    },
    methods: {
      countrySelected(obj) {
        this.country = obj.display;
        this.country_id = obj.value;
      },
      citySelected(obj) {
        this.city = obj.display;
        this.city_id = obj.value;
        this.getAllChurches();
      },
      churchSelected(obj) {
        this.church = obj.display;
        this.church_id = obj.value;
      },
      ministrySelected(obj) {
        this.ministry_id = obj.value;
        this.ministry = obj.display;
      },
      findCityUrl() {
        return `/cities?country_id=${this.country_id}&name=`;
      },
      findChurchUrl() {
        return `/churches?city_id=${this.city_id}&name=`;
      },
      clearCountry() {
        this.country_id = null;
        this.country = null;
        this.clearCity();
      },
      clearCity() {
        this.city_id = null;
        this.city = null;
        this.clearChurch();
        this.churches = [];
      },
      clearChurch() {
        this.church = undefined;
        this.church_id = undefined;
        // this.churches = [];
      },
      clearMinistry() {
        this.ministry_id = undefined;
        this.ministry = undefined;
      },
      cancel() {
        this.$emit('cancel');
      },
      async search() {
        this.isSearching = true;
        const searchData = JSON.parse(JSON.stringify({
          country_id: this.country_id ? this.country_id : undefined,
          city_id: this.city_id ? this.city_id : undefined,
          church_id: this.church_id ? this.church_id : undefined,
          ministry_id: this.ministry_id ? this.ministry_id : undefined,
          isParticipient: this.isParticipient !== '-' ? this.isParticipient === '1' : undefined,
          isPrereg: this.isPrereg !== '-' ? this.isPrereg === '1' : undefined,
          isArrived: this.isArrived !== '-' ? this.isArrived === '1' : undefined,
          isAccomodate: this.isAccomodate !== '-' ? this.isAccomodate === '1' : undefined,
          fullname: this.fullname ? this.fullname : undefined,
          sex: this.sex !== '-' ? this.sex : undefined,
          ageFrom: this.ageFrom > 0 ? this.ageFrom : undefined,
          ageTo: this.ageTo > 0 ? this.ageTo : undefined,
          mobile: this.mobile ? this.mobile : undefined,
          email: this.email ? this.email : undefined

        }));
        if (Object.keys(searchData).length === 0) {
          this.isSearching = false;
          return false;
        }
        try {
          let { body: rows } = await this.getData('users', searchData);
          this.found = rows.map(item => {
            item.birthday = moment(item.birthday).format('DD.MM.YYYY');
            switch (item.sex) {
              case 'm':
                item.sex = 'М';
                break;
              case 'w':
                item.sex = 'Ж';
                break;
              default:
                break;
            }
            if (this.fullname) {
              const startIndex = item.fullname.toLowerCase().indexOf(this.fullname.toLowerCase());
              const before = item.fullname.substr(0, startIndex);
              const it = `<span style="color: red">${item.fullname.substr(startIndex, this.fullname.length)}</span>`;
              const after = item.fullname.substr(startIndex + this.fullname.length, item.fullname.length);
              item.fullname = `${before}${it}${after}`;
            }
            return item;
          });
          this.isSearching = false;
        } catch (e) {
          this.isSearching = false;
          console.error(e);
        }
      },
      go(event) {
        const uid = event.target.closest('tr').getAttribute('data-uid');
        this.$router.push(`/user/${uid}`);
      },
      async getAllMinistries() {
        const { body } = await this.getData('/ministries');
        this.ministries = body.map(item => {
          return { label: item.name, value: item.id };
        });
      },
      async getAllChurches() {
        const { body } = await this.getData(`/churches?city_id=${this.city_id}`);
        this.churches = body.map(item => {
          return { label: item.name, value: item.id };
        });
      }
    },
    mounted() {
      this.$refs.fullname.focus();
      this.getAllMinistries();
    },
    computed: {
      churchObj: {
        get: function () {
          return {
            label: this.church,
            value: this.church_id
          }
        },
        set: function (val) {
          if (!val) {
            return this.clearChurch();
          }
          this.church_id = val.value;
          this.church = val.label;
        }
      },
      ministryObj: {
        get: function () {
          return {
            label: this.ministry,
            value: this.ministry_id
          }
        },
        set: function (val) {
          if (!val) {
            return this.clearMinistry();
          }
          this.ministry_id = val.value;
          this.ministry = val.label;
        }
      }
    },
    components: {
      AutoComplete,
      vSelect
    }
  }
</script>

<style scoped lang="scss">
    .age {
        width: 70px;
        margin-left: 10px;
        margin-right: 20px;

        height: calc(2.25rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 0.8rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    }

    h6.label {
        display: block;
    }

    .card-header {
        cursor: pointer;
    }

</style>
