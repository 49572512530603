<template>
    <div>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <h4>Сдача денег</h4>
                <form @submit.prevent="doMoneyTransfer">
                    <div class="form-group">
                        <label class="label">Сумма</label>
                        <input type="number" class="form-control" v-model.number="amount" autocomplete="off"/>
                    </div>
                    <div class="form-group">
                        <label class="label">Кому</label>
                        <select v-model='dst' class="form-control">
                            <option v-for="admin in admins" v-bind:key="admin.id" v-bind:value="admin.id">{{admin.fullname}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="label">Пароль</label>
                        <input type="password" v-model.trim="secret"
                               @keydown="error=null"
                               class="form-control"/>
                    </div>
                    <div class="alert alert-danger" role="alert" v-if="error">
                        {{error}}
                    </div>
                    <button type="submit" class="btn btn-primary">Передать</button>
                </form>
            </div>

        </div>

        <div class="row" style="padding-top: 35px">
            <div class="col-md-8 offset-md-2">
                <h4>Сдано ранее</h4>
                <table class="table table-stripped ">
                    <thead>
                    <tr>
                        <th scope="col">№№</th>
                        <th scope="col">ID</th>
                        <th width="200px" align="center" scope="col">дата</th>
                        <th scope="col">кто</th>
                        <th scope="col">кому</th>
                        <th scope="col">сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                            v-for="(item, index) in moneyHistory"
                            :key="item.id"
                    >
                        <td scope="row">{{index+1}}</td>
                        <td scope="row">{{item.id}}</td>
                        <td scope="row">{{$moment(item.dt).format('DD.MM.YYYY HH:mm:ss')}}</td>
                        <td scope="row">{{item.src}}</td>
                        <td scope="row">{{item.dst}}</td>
                        <td scope="row">{{item.money}}</td>
                    </tr>
                    </tbody>

                </table>
            </div>

        </div>
    </div>
</template>

<script>

    export default {
      name: 'money',
      data: function () {
        return {
          dst: null,
          secret: null,
          amount: null,
          moneyHistory: [],
          admins: [],
          retreatData: null,
          error: null
        }
      },
      methods: {
        async doMoneyTransfer() {
          if (this.secret.length < 3) {
            return;
          }
          const postData = {
            dst: this.dst,
            money: this.amount,
            secret: this.secret
          };
          try {
            await this.postData(`/money`, postData, { ignoreUnauthorized: true });
            await this.fetchHistory();
            this.secret = '';
            this.dst = null;
            this.amount = 0;
          } catch (e) {
            console.error(e);
            if (e.status === 401) {
              this.error = 'не верный пароль';
            }
          }
        },
        async fetchHistory() {
          const { body: { money, moneyHistory } } = await this.getData(`/money`);

          this.moneyHistory = moneyHistory;
          this.amount = money;
        }
      },
      mounted: async function () {
        await this.fetchHistory();
        const { body: admins } = await this.getData(`admin/admins`);
        this.admins = admins;
      }
    }

</script>
