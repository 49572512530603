<template>
    <div class="user-data-container">
        <label class="label">Данные служителя</label>
        <a href="" @click.prevent="$emit('edit')" class="edit"> <img src="@/assets/edit_user_male-40.png" width="32px"/></a>
        <span class="prohibited" v-if="userData.prohibited">На ретрит не пущать!!!</span>
        <table class="table">
            <tr>
                <td >UID</td>
                <td>{{userData.uid}}</td>
            </tr>
            <tr>
                <td>Ф.И.О.</td>
                <td>{{userData.fullname}}</td>
            </tr>
            <tr>
                <td>Д/р</td>
                <td>{{getBirthday}}</td>
            </tr>
            <tr>
                <td>Пол</td>
                <td>{{getSex}}</td>
            </tr>
            <tr>
                <td>Страна</td>
                <td>{{userData.country}}</td>
            </tr>
            <tr>
                <td>Город</td>
                <td>{{userData.city}}</td>
            </tr>
            <tr>
                <td>Церковь</td>
                <td>{{userData.church}}</td>
            </tr>
            <tr>
                <td>Служение</td>
                <td>{{userData.ministry}}</td>
            </tr>
            <tr>
                <td>Мобильный</td>
                <td>{{userData.mobile}}</td>
            </tr>
            <tr>
                <td>email</td>
                <td>{{userData.email}}</td>
            </tr>
            <tr>
                <td>Отказ от рассылок</td>
                <td>
                    <label v-if="isPermited(4)" class="switch ">
                        <input type="checkbox" class="danger" v-model="no_mailing">
                        <span class="slider round"></span>
                    </label>
                    <template v-else>
                        {{no_mailing?'Да':''}}
                    </template>

                </td>
            </tr>
            <tr class="ret-data">
                <td>Пред.рег</td>
                <td>
                    <label v-if="isPermited(4)" class="switch ">
                        <input type="checkbox" class="success" v-model="prereg">
                        <span class="slider round"></span>
                    </label>
                    <template v-else>
                        {{prereg ? 'Да': '-'}}
                    </template>
                </td>
            </tr>
            <tr class="ret-data">
                <td>Скидка, %</td>
                <td v-if="isPermited(4)">
                    <input class="form-control" type="number" max="100" min="0" style="max-width:80px"
                           v-model.number="discount_percent"/>
                </td>
                <td v-else>
                    {{userRet.discount_percent}}
                </td>
            </tr>
            <tr v-if="isParticipient" class="ret-data">
                <td>Оплачено</td>
                <td>{{userRet.money}}</td>
            </tr>
            <tr v-if="isParticipient" class="ret-data">
                <td>Долг</td>
                <td>{{userRet.borg}}</td>
            </tr>
            <tr class="ret-data">
                <td>Комманда</td>
                <td>
                    <label v-if="isPermited(4)" class="switch ">
                        <input type="checkbox" class="primary" v-model="commanda">
                        <span class="slider round"></span>
                    </label>
                    <template v-else>
                        {{commanda ? 'Да': '-'}}
                    </template>
                </td>
            </tr>
            <tr class="ret-data " :class="{'recommendation': userRet.recommendationChecker}">
                <td>Рекомендация проверена</td>
                <td v-if="userRet.recommendationChecker === -1" class="recommendation"><b>Не требуется</b></td>
                <td v-else-if="userRet.recommendationChecker">
                    {{userRet.recommendationChecker}}<br/>
                    <sup>{{$moment(userRet.recommendationCheckDate).format('DD.MM.YYYY HH:mm')}}</sup>
                </td>
                <td v-else>
                    <label class="switch ">
                        <input type="checkbox" class="info" v-model="recommendation">
                        <span class="slider round"></span>
                    </label>
                </td>
            </tr>
            <tr v-if="isParticipient" class="ret-data">
                <td>Прибытие</td>
                <td>{{getDtArrived}}</td>
            </tr>

        </table>
        <button
                class="btn btn-primary"
                @click="reg"
                name="reg"
                :disabled="userData.prohibited"
        >Продолжить
        </button>
    </div>
</template>

<script>
  export default {
    data: function () {
      return {
        recommendation: false,
        discount_percent_dirty: false,
        $isParticipient: false
      }
    },
    props: {
      userData: {
        type: Object,
        required: true
      },
      userRet: {
        type: Object,
        required: false
      },
      invoices: {
        type: Array,
        required: false
      },
      uid: {
        type: Number,
        required: true
      }
    },
    computed: {
      getBirthday() {
        return this.$moment(this.userData.birthday).format('DD.MM.YYYY');
      },
      getSex() {
        if (this.userData.sex === 'm') {
          return 'М';
        }
        if (this.userData.sex === 'w') {
          return 'Ж';
        }
        return '?';
      },
      isParticipient() {
        // eslint-disable-next-line no-mixed-operators
        return (this.$isParticipient || this.userRet && this.userRet.uid === this.userData.uid);
      },
      getDtArrived() {
        if (this.userRet && this.userRet.dt_arrived) {
          return this.$moment(this.userRet.dt_arrived).format('DD.MM.YYYY HH:mm')
        }
        return '';
      },
      no_mailing: {
        set: async function (val) {
          await this.patchData(`/users/${this.userData.uid}`, { no_mailing: val });
          this.userData.no_mailing = val;
        },
        get: function () {
          return this.userData.no_mailing;
        }
      },
      prereg: {
        set: async function (val) {
          await this.patch({ prereg: val });
          this.userRet.prereg = val;
        },
        get: function () {
          return this.userRet.prereg;
        }
      },
      commanda: {
        set: async function (val) {
          await this.patch({ commanda: val });

          this.userRet.commanda = val;
        },
        get: function () {
          return this.userRet.commanda;
        }
      },
      discount_percent: {
        get: function () {
          return this.userRet.discount_percent;
        },
        set: function (val) {
          this.discount_percent_dirty = true;
          this.userRet.discount_percent = val;
        }
      }

    },
    methods: {
      async reg() {
        const body = {};
        if (this.discount_percent_dirty) {
          body.discount_percent = this.userRet.discount_percent;
        }

        if (this.recommendation) {
          body.recommendationChecker = true;
        }
        if ((Object.keys(body)).length > 0) {
          await this.patch(body);
        }

        this.$emit('reg', this.userData.uid);
      },
      async patch(obj) {
        if (this.isParticipient) {
          await this.patchData(`/users/${this.userData.uid}/ret`, obj);
        } else {
          await this.postData(`/users/${this.userData.uid}/ret`, obj);
        }
        this.$isParticipient = true;
      }
    },
    components: {
    }
  }
</script>
<style lang="scss" scoped>
    .edit {
        position: absolute;
        margin-left: 18px;
        margin-top: -5px;
        cursor: pointer;
        padding: 3px;
    }
    .edit:hover {
        background-color: lawngreen;
    }
    .prohibited {
        margin-left: 70px;
        font-size: 24px;
        color: red;
        font-weight: bold;
    }
    .ret-data {
        font-family: "Courier New";
    }
    .recommendation {
        background-color: #ddffdd;
    }
    .switch {
        float: left;
        margin-left: 10px;
    }
    .switch :host {
        padding-top: 5px;
    }
    .user-data-container{
        max-width: 100%;
        overflow: scroll;

    }
    table.table tr td:first-child{
        width: 120px;
    }

</style>
