<template>
    <div>
        <div v-if="mode ==='notFound'" class="row">
            <div class="col-md-8 offset-md-1 alert alert-danger">
                Не найдено
            </div>
        </div>
        <div class="row" v-if="mode === 'view'">
            <user-data-viewer
                    class="col-md-5 col-sm-10 offset-sm-1"
                    :userData="userData"
                    :userRet="userRet"
                    :invoices="invoices"
                    :uid="uid"
                    @reg="reg"
                    @edit="mode='edit'"
            ></user-data-viewer>
            <user-comments
                    class="col-md-5 col-sm-10 offset-sm-1"
                    :uid="userData.uid"
            ></user-comments>

            <user-invoices class="col-md-10 offset-md-1 " :uid="uid"></user-invoices>
            <user-history class="col-md-10 offset-md-1 " :uid="uid"></user-history>

        </div>

        <div class="row" v-if="mode === 'edit' ">
            <user-edit
                    class="col-md-10 offset-md-1"
                    :userData="Object.assign({}, userData)"
                    :userRet="Object.assign({}, userRet)"
                    @cancel="canceled"
                    @saved="saved"
            >

            </user-edit>
        </div>
    </div>
</template>

<script>
  // @ is an alias to /src
  import UserDataViewer from '@/components/user/userDataViewer';
  import UserInvoices from '@/components/user/userInvoices';
  import UserComments from '@/components/user/comments';
  import UserEdit from '@/components/user/userEdit';
  import UserHistory from '@/components/user/userHistory';
  export default {
    name: 'users',
    data: function () {
      return {
        uid: undefined,
        userData: {},
        userRet: {},
        mode: 'notReady',
        invoices: []
      };
    },
    methods: {
      async getUser() {
        this.mode = 'search';
        try {
          const { body } = await this.getData(`/users/${this.uid}`);
          if (typeof body === 'object' && Object.keys(body).length > 0) {
            this.userData = body;
            // console.log(this.userData);
            await this.getUserRet();
            this.mode = 'view';
          }
        } catch (e) {
          if (e.status === 404) {
            this.mode = 'notFound';
            return;
          }
          console.error(e);
        }
      },
      async getUserRet() {
        try {
          const { body } = await this.getData(`/users/${this.uid}/ret`);
          if (typeof body === 'object' && Object.keys(body).length > 0) {
            this.userRet = body;
          }
        } catch (e) {
          console.warn(e);
        }
      },
      async getInvoices() {
        this.invoices = [];
        try {
          const { body } = await this.getData(`/users/${this.uid}/invoices`);
          if (typeof body === 'object' && Object.keys(body).length > 0) {
            this.invoices = body;
          }
        } catch (e) {
          console.warn(e);
        }
      },
      reg() {
        // console.log('reg');
        this.$router.push(`/reg/${this.userData.uid}`);
      },
      canceled() {
        if (this.uid > 0) {
          this.mode = 'view';
          // await this.getUser();
          return;
        }
        this.$router.go(-1);
      },
      async saved(uid) {
        if (typeof (uid) === 'number') {
          this.uid = uid;
        }
        await this.getUser();
        this.mode = 'view';
      }
    },
    async mounted() {
      this.uid = Number.parseInt(this.$route.params.uid);
      this.$loading(true);
      if (this.uid > 0) {
        await this.getUser();
        if (this.isPermited(4)) {
          this.getInvoices();
        }
      } else {
        this.mode = 'edit';
      }
      this.$loading(false);
    },
    components: {
      UserDataViewer,
      UserComments,
      UserEdit,
      UserInvoices,
      UserHistory
    }
  };
</script>

<style lang="scss" scoped>
    div.alert {
        font-size: 24px;
    }
</style>
