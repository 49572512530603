<template>
    <div v-if="($isActive() || isPermited(4)) && (isPermited(1) || isPermited(2))">
        <user-search
                @onUID="getUser"
                @new="addNew"
                @advancedSearch="mode='advanced'"
                v-if="mode==='base'"
                @partFullname="partFullnameMethod"
        ></user-search>
        <advanced-search
                v-else
                @cancel="mode='base'"
        ></advanced-search>
    </div>
    <div v-else-if="ready" style="text-align: center; color: crimson">
        <h3>Нет разрешения работать вне ретрита</h3>
    </div>

</template>

<script>
  // @ is an alias to /src
  import UserSearch from '@/components/search/index';
  import AdvancedSearch from '@/components/search/advancedSearch';

  export default {
    name: 'index',
    data: function () {
      return {
        mode: 'base',
        partFullname: '',
        ready: false
      }
    },
    components: {
      UserSearch,
      AdvancedSearch
    },
    methods: {
      getUser(uid) {
        this.$router.push(`/user/${uid}`);
      },
      addNew() {
        this.$router.push(`/user/0`);
      },
      partFullnameMethod(value) {
        this.partFullname = value;
      }
    },
    async created() {
      try {
        const { body } = await this.getData(`/index`);
        for (let key in body) {
          this.$session.set(key, body[key]);
        }
        this.$parent.$forceUpdate();
        this.ready = true;
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>
