<template>
    <div class="invoices">
        <h5>Выставленные счета</h5>
        <table class="table table-bordered">
            <tr>
                <td>Создан</td>
                <td>Дни</td>
                <td>Ночи</td>
                <td>Перевод</td>
                <td>Бейдж</td>
                <td>Сумма</td>
                <td>Проведен</td>
                <td>url</td>
            </tr>
            <tr v-for="invoice in invoices"
                :key="invoice.uuid"
                :class="'transactionStatus-'+invoice.transactionStatus"
            >
                <td :title="invoice.transactionStatus">{{$moment(invoice.createdDate).format('DD.MM.YYYY hh:mm')}}</td>
                <td>{{invoice.days}}</td>
                <td>{{invoice.nights}}</td>
                <td>{{invoice.interpriters}}</td>
                <td>{{invoice.bage ? 'Да': '&nbsp;'}}</td>
                <td>{{invoice.amount}}</td>
                <td><span v-if="invoice.dt_arrived">{{invoice.dt_arrived}} ({{invoice.aid}})</span></td>
                <td align="center" class="arrow" v-clipboard:copy="invoice.invoiceUrl">
                    <i class="fa fa-clone" ></i>
                </td>
            </tr>
        </table>
    </div>

</template>

<script>
  export default {
    name: 'userInvoices',
    props: {
      uid: {
        type: Number,
        required: false
      }
    },
    data: function () {
      return {
        invoices: []
      }
    },
    async mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        if (!this.uid) {
          return;
        }
        try {
          const { body } = await this.getData(`/users/${this.uid}/invoices`);
          if (typeof body === 'object' && Object.keys(body).length > 0) {
            this.invoices = body;
          }
          return this.invoices;
        } catch (e) {
          console.warn(e);
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
    .invoices{
        padding-top: 20px;

    }
    .transactionStatus-approved{
        background-color: #ddffdd;
    }
    .transactionStatus-declined{
        background-color: #f8d7da;
    }
    .transactionStatus-pending{
        background-color: #FFF9B9;
    }
    .transactionStatus-refunded{
        background-color: #f0f0f0;
    }
    .invoices{
        max-width: 100%;
    }

</style>
