<template>
    <div>
        <form @submit.prevent="save">
            <div class="row">
                <div v-if="error || htmlError" class="error">
                    <div class="alert alert-danger">
                        <div class="close" @click="closeError">x</div>
                        {{error}}
                        <div v-if="htmlError">
                            <h4>Конфликт {{htmlError.field}}</h4>
                            <ul>
                                <li  v-for="uid in htmlError.uids" :key="uid">
                                    <router-link :to="'/user/'+uid" target="_blank">{{uid}}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-10 offset-xl-1" v-else>
                    <div class="card">
                        <div class="card-header" :class="{ prohibited: prohibited }">Личные данные</div>
                        <div class="card-body row">
                            <div class="form-group col-md-8 ">
                                <label class="label">Ф.И.О.</label>
                                <input
                                        class="form-control"
                                        v-model.trim="userData.fullname"
                                        autocomplete="off"
                                        ref="fio"
                                        required
                                        name="Ф.И.О."
                                        v-validate="'required|min:7'"
                                />

                            </div>
                            <div class="form-group col-md-4 ">
                                <label class="label">Дата рождения</label>
                                <date-component
                                        v-model="userData.birthday"
                                        v-bind:time="false"
                                ></date-component>

                            </div>
                            <div class="form-group col-md-2 ">
                                <label class="label">Пол</label>
                                <select
                                        class="form-control"
                                        v-model="userData.sex"
                                        name="Пол"
                                        v-validate="'excluded:-'"
                                >
                                    <option value="-">-</option>
                                    <option value="m">М</option>
                                    <option value="w">Ж</option>
                                </select>
                                <div v-if="error && error.field==='sex'" class="popover bs-popover-left sex ">
                                    <div class="arrow"></div>
                                    <div class="popover-body">
                                        {{ error.message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-4 ">
                                <label class="label">email</label>
                                <input
                                        type="email"
                                        class="form-control"
                                        v-model.trim="userData.email"
                                        v-validate="'email'"
                                        name="email"
                                        autocomplete="off"
                                >
                                <div v-if="error && error.field==='email'" class="popover bs-popover-right email ">
                                    <div class="arrow"></div>
                                    <div class="popover-body">
                                        {{ error.message }}
                                    </div>
                                </div>

                            </div>

                            <div class="form-group col-md-6 ">
                                <label class="label">Мобильный (380xxxxxxxxx, 79xxxxxxxxxx)</label>
                                <input v-model="userData.mobile" type="tel" class="form-control">
                            </div>

                            <div class=" col-md-8 label">
                                <input
                                        type="checkbox"
                                        class="-input"
                                        v-model="prohibited"
                                        id="prohibited"
                                >
                                &nbsp;
                                <label for="prohibited"
                                       class="-label"
                                       style="color: #f77"
                                >На ретрит НЕ ПУЩАТЬ!</label>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            Данные церкви
                        </div>
                        <div class="card-body row">
                            <div class="form-group col-md-6">
                                <auto-complete2
                                        caption='Страна'
                                        :source='countries'
                                        @selected="countrySelected"
                                        :initial-value="userData.country_id"
                                        :alert="countryError"
                                ></auto-complete2>
                            </div>
                            <div class="form-group col-md-6">
                                <auto-complete2
                                        caption='Город'
                                        :source='cities'
                                        :disabled="!userData.country_id"
                                        @selected="citySelected"
                                        :initial-value="userData.city_id"
                                        :can-add=true
                                        @addItem="cityAdd"
                                        :alert="cityError"
                                ></auto-complete2>

                            </div>
                            <div class="form-group col-md-6">

                                <auto-complete2
                                        caption="Церковь (Община)"
                                        :source='churches'
                                        :disabled="!userData.city_id"
                                        @selected="churchSelected"
                                        :initial-value="userData.church_id"
                                        :can-add=true
                                        @addItem="churchAdd"
                                        :alert="churchError"
                                ></auto-complete2>

                            </div>
                            <div class="form-group col-md-6">

                                <auto-complete2
                                        caption='Служение'
                                        :source='ministries'
                                        @selected="ministrySelected"
                                        :initial-value="userData.ministry_id"
                                        :can-add=false
                                        :alert="ministryError"
                                ></auto-complete2>

                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 15px">
                        <div class="col-md-3 offset-md-3">
                            <button class="btn btn-secondary" type="cancel" @click="cancel">Отмена</button>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <button  class="btn btn-primary">Сохранить</button>

                        </div>
                    </div>
                </div>
            </div>

        </form>

    </div>

</template>

<script>
  import AutoComplete2 from './AutoComplete2';

  export default {
    data: function () {
      return {
        error: null,
        htmlError: null,
        $canSave: false,
        prohibited: false,
        countries: [],
        cities: [],
        churches: [],
        ministries: [],
        countryError: false,
        cityError: false,
        churchError: false,
        ministryError: false,
        recommendataionChecked: false
      }
    },
    props: {
      userData: {
        type: Object,
        required: true,
        default: function () {
          return {}
        }
      },
      userRet: {
        type: Object,
        required: false
      },
      canSave: {
        set(val) {
          this.$canSave = val;
        },
        get() {
          return this.$canSave;
        },
        required: false,
        default: true
      }
    },

    methods: {
      closeError() {
        console.log('closeError');
        this.error = null;
        this.htmlError = null;
      },
      async upload() {
        console.log('upload');
        const f = document.createElement('input');
        f.type = 'file';
        f.click();
      },
      async save() {
        if (!this.canSave) {
          return;
        }
        const userData = {
          fullname: this.userData.fullname,
          birthday: this.$moment(this.userData.birthday).format('YYYY-MM-DD'),
          sex: this.userData.sex,
          email: this.userData.email ? this.userData.email.trim().toLowerCase() : '',
          mobile: this.userData.mobile,
          prohibited: this.prohibited,
          church_id: this.userData.church_id,
          ministry_id: this.userData.ministry_id,
          no_mailing: this.userData.no_mailing
        };
        // if(userData.email.match(/@ukr.net$/)){
        //   this.error = 'Почта @ukr.net настоятельно не рекомендована к использованию';
        //   return;
        // }
        // if(userData.fullname.invalid())

        const userRet = {
          discount_percent: this.userRet.discount_percent,
          commanda: this.userRet.commanda,
          prereg: this.userRet.prereg,
          recommendationChecker: this.userRet.recommendationChecker
        };

        if (!this.userData.country_id) {
          this.countryError = true;
          return;
        }
        if (!this.userData.city_id) {
          this.cityError = true;
          return;
        }
        if (!userData.church_id) {
          this.churchError = true;
          return;
        }
        if (!userData.ministry_id) {
          this.ministryError = true;
          return;
        }

        if (this.userData.uid) {
          try {
            await Promise.all([
              this.putData(`/users/${this.userData.uid}`, userData),
              this.postData(`/users/${this.userData.uid}/ret`, userRet)
            ]);
            this.$emit('saved');
          } catch (e) {
            console.error(e);
            if (e.status === 409) {
              this.htmlError = e.body;
              console.log(this.htmlError);
              return;
            }
            this.error = e.body;
          }
          return;
        }

        try {
          const { body: newUID } = await this.postData(`/users`, userData);
          await this.postData(`/users/${newUID}/ret`, userRet);
          this.$emit('saved', newUID);
        } catch (e) {
          console.error(e);
          if (e.status === 409) {
            this.htmlError = e.body;
            return;
          }
          this.error = e.body;
        }
      },
      async countrySelected(obj) {
        // console.info('countrySelected');
        if (!obj) {
          this.userData.country_id = null;
          this.cities = [];
          this.citySelected(null);
          this.churchSelected(null);
          return
        }
        if (this.userData.church_country_id !== obj.value) {
          this.countryError = false;
          this.userData.country_id = obj.value;
          const { body } = await this.getData(`/cities?country_id=${obj.value}`);
          this.cities = body.map(item => {
            return {
              label: item.name,
              value: item.id
            };
          });
        }
      },
      async citySelected(obj) {
        if (!obj) {
          this.userData.city_id = null;
          this.churches = [];
          return this.churchSelected(null);
        }
        this.cityError = false;
        this.userData.city_id = obj.value;
        const { body } = await this.getData(`/churches?city_id=${obj.value}`);
        this.churches = body.map(item => {
          return {
            label: item.name,
            value: item.id
          };
        });
      },
      churchSelected(obj) {
        if (!obj) {
          this.userData.church_id = null;
          return;
        }
        this.churchError = false;
        this.userData.church_id = obj.value;
      },
      ministrySelected(obj) {
        if (!obj) {
          this.userData.ministry_id = null;
          return;
        }
        this.ministryError = false;
        this.userData.ministry_id = obj.value;
      },

      async cityAdd(name) {
        console.log(name);
        this.userData.church = null;
        this.userData.church_id = null;
        const { body } = await this.postData(`cities`, {
          country_id: this.userData.country_id,
          name: name
        });
        this.cities.push({
          label: name,
          value: body
        });
        // this.userData.city = name;
        this.userData.city_id = body;
      },
      async churchAdd(name) {
        this.userData.church_id = null;
        const { body } = await this.postData(`/churches`, {
          city_id: this.userData.city_id,
          name: name
        });
        this.churches.push({
          label: name,
          value: body
        });

        // this.userData.church = name;
        this.userData.church_id = body;
      },
      cancel() {
        this.$canSave = false;
        this.$emit('cancel');
      }
    },
    components: {
      AutoComplete2
    },
    async mounted() {
      this.recommendataionChecked = Boolean(this.userRet.recommendationChecker);
      this.canSave = true;
      this.prohibited = this.userData.prohibited;
      this.$refs.fio.focus();

      let { body } = await this.getData('/countries');
      this.countries = body.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });

      ({ body } = await this.getData(`/ministries`));
      this.ministries = body.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      if (this.userData.country_id) {
        await this.countrySelected({
          value: this.userData.country_id
        });
      }
      if (this.userData.city_id) {
        await this.citySelected({
          value: this.userData.city_id
        });
      }
    },
    computed: {
      cityObj: {
        get: function() {
          return {
            label: this.userData.city,
            value: this.userData.city_id
          }
        },
        set: function(val) {
          if (!val) {
            return this.clearCity();
          }
          this.userData.city_id = val.value;
          this.userData.city = val.label;
          this.getAllChurches();
        },
        churchObj: {
          get: function () {
            return {
              label: this.userData.church,
              value: this.userData.church_id
            }
          },
          set: function (val) {
            if (!val) {
              return this.clearChurch();
            }
            this.userData.church_id = val.value;
            this.userData.church = val.label;
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    @import '~bootstrap/scss/bootstrap.scss';
    .lighted {
        color: red;
        font-weight: bold;
    }
    .prohibited {
        background-color: red;
        color: white;
    }

    .popover.fullname{
        position: absolute;
        top: 0;
        left: 100%;
        right: -250px;
    }
    .popover.email{
        position: absolute;
        top: 0;
        left: 100%;
        right: -250px;
    }
    .popover.sex{
        position: absolute;
        top: 50%;
        left: -200px;
        right: 100px;
    }
    .popover.country{
        position: absolute;
        top: 50%;
        left: -120px;
        width: 120px;
    }
    .popover.city{
        position: absolute;
        top: 20%;
        left: 97%;
        width: 120px;
        /*right: -250px;*/
    }
    .popover .popover-body{
        font-size: 0.9rem;
        font-family: "Arial";
        color: red;
        background-color: #FFF9B9;
    }

    .popover.bs-popover-right .arrow {
        top: 24px;
    }
    .error{
        @extend .col-10;
        @extend .offset-1;
        .close{
            border-radius: 24px;
            width: 24px;
            height: 24px;
            position: relative;
            right: -18px;
            top: -24px;
            font-size: 14px;
            border: 1px #000 solid;
            cursor: pointer;
            z-index: 999;
            padding: 4px 4px 6px 7px;
        }
    }
    .conflict{
        display: block;
    }

</style>
