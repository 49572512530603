<template>
  <div class="row" @click="rowClick">
    <div class="maindiv">
      <div class="left">
        <div class="card" style="margin-bottom: 5px">
          <div class="card-header">
            <div style="display: inline-block;float:left">Итого</div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-6">
                <i class="fa fa-id-card"></i>
              </div>
              <div class="col-4">
                {{ userData.bage_count }}
                / {{ invoiceBages || '-' }}
              </div>
              <div class="col-1">
                <input
                  type="checkbox"
                  class="form-check-input"
                  @change="addBage"
                  value="1"
                />
              </div>
            </div>

            <div class="form-group row ">
              <div class=" col-6">доп.скидка</div>
              <div class="col-6">
                <input
                  type="number"
                  class="form-control form-control-ssm "
                  v-model.number="userData.discount"
                  @change="dirty = true"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-6">скидка %</div>
              <div class="col-6">
                <input
                  class="form-control form-control-ssm"
                  v-model="userData.discount_percent"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">&sum;</div>
              <div class="col-6">
                <i
                  @click="calculate"
                  class="fa fa-calculator float-right"
                  style="cursor: pointer; font-size: 1.5em;"
                  v-if="dirty"
                ></i>
                <template v-else>
                  <input
                    class="form-control form-control-ssm"
                    v-model="total"
                    readonly
                  />

                  <i
                    class="fa  calculate-log-visiblity-control"
                    :class="isCalculateLogVisible ? 'fa-eye' : 'fa-eye-slash'"
                    @click="toggleCalculatedLog"
                  ></i>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">Cкидка</div>
              <div class="col-6">
                <input
                  class="form-control form-control-ssm"
                  :class="{ 'invisible-grey': dirty }"
                  v-model.number="totalDiscount"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">Оплачено</div>
              <div class="col-6">
                <input
                  class="form-control form-control-ssm"
                  :class="{ 'invisible-grey': dirty }"
                  v-model="paid"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">К оплате</div>
              <div class="col-6">
                <input
                  style="cursor: pointer"
                  v-bind:style="{ color: debt < 0 ? 'red' : '' }"
                  v-model="debt"
                  class="form-control form-control-ssm"
                  :class="{ 'invisible-grey': dirty }"
                  readonly
                  @dblclick="fullAmount"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">Оплата</div>
              <div class="col-6">
                <input
                  type="number"
                  class=" form-control form-control-ssm"
                  v-model="userData.paid"
                />
              </div>
            </div>
            <div v-if="isPermited(4)" class="form-group row">
              <div class="col-9">Выставить счет</div>
              <div class="col-1">
                <input type="checkbox" v-model="invoiceMode" />
              </div>
            </div>
          </div>
          <div>
            <i
              @click="addComment"
              class="fa fa-comments float-left"
              style="margin-left:10px; margin-top: 5px; cursor: pointer; font-size: 1.5rem"
            ></i>
            <button
              class="btn btn-success float-right"
              style="margin-right: 5px;margin-bottom: 5px"
              @click="processRegisteration"
            >
              Регистрация
            </button>
          </div>
        </div>
      </div>

      <div class="main">
        <div class="row">
          <div class="userFIO">
            <i @click="init" style="cursor: pointer" class="fa fa-refresh "></i>
            {{ userData.fullname }}, UID={{ uid }}
          </div>

          <div class="prereg col-6 offset-3">
            <span class="addInfo" v-if="userData.commanda">комманда</span
            ><span v-if="userData.commanda && userData.prereg">, </span
            ><span class="addInfo" v-if="userData.prereg"
              >предварительная регистрация</span
            >
          </div>

          <div
            style="margin-top: 10px; margin-bottom: 10px; "
            class="col-8 offset-1 col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-10 offset-sm-1"
          >
            <label class="label" style="margin-right: 10px;">прибытие</label>
            <flat-pickr
              v-bind:disabled="isArrived"
              class="pointer dt-arrived"
              :config="dtArrivedConfig"
              v-model="userData.dt_arrived"
            ></flat-pickr>
          </div>
        </div>

        <div class="calculate-log" v-if="isCalculateLogVisible && !dirty">
          <h4 class="text-center">Калькуляция</h4>
          <table
            class="table"
            v-if="Array.isArray(calculateLog) && calculateLog.length > 0"
          >
            <tr>
              <th>Наименование</th>
              <th>к-во</th>
              <th>цена</th>
              <th>&sum;</th>
            </tr>
            <tr v-for="log in calculateLog" :key="log.id">
              <td v-html="log.name"></td>
              <td>{{ log.count }}</td>
              <td>{{ log.value }}</td>
              <td>{{ log.amount }}</td>
            </tr>
          </table>
        </div>
        <!-- Дни ретрита -->
        <div class="daysAndNights days">
          <table align="center">
            <thead>
              <tr>
                <th v-bind:colspan="retreatData.dayCount">Утренее лужение</th>
              </tr>
            </thead>
            <tbody>
              <tr class="day">
                <td
                  v-for="day in retreatData.dayCount"
                  @click="toggleDay"
                  v-bind:data-id="day"
                  v-bind:class="{ active: isDayActive(day) }"
                  v-bind:key="day"
                >
                  <i
                    class="fa fa-headphones daily-interpreter-mark"
                    v-bind:class="{ active: isInterpreterActive(day) }"
                    @click="toggleInterpreter"
                  ></i>

                  {{ getRetreatDay(day) }}
                  <br />
                  {{ getRetreatDate(day) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- class="days" -->
        <!-- Ночи ретрита -->
        <div class="daysAndNights nights" v-if="retreatData.nightCount">
          <table align="center">
            <thead>
              <tr>
                <th v-bind:colspan="retreatData.nightCount - 1">
                  Вечернее служение
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="night">
                <td
                  v-for="night in retreatData.nightCount - 1"
                  v-bind:data-id="night"
                  @click="toggleDay"
                  v-bind:class="{ active: isNightActive(night) }"
                  v-bind:key="night"
                >
                  <i
                    class="fa fa-headphones daily-interpreter-mark"
                    v-bind:class="{ active: isInterpreterActive2(night) }"
                    @click="toggleInterpreter2"
                  ></i>
                  {{ getRetreatDay(night) }}
                  <br />
                  {{ getRetreatDate(night) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- class="nights" -->
        <div class="history">
          <div class="card-header">История платежей</div>
          <div class="card-body" style="padding: 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th title="Дата"><i class="fa fa-calendar-check-o"></i></th>
                  <th title="Дни"><i class="fa fa-sun-o"></i></th>
                  <th title="Ночи"><i class="fa fa-moon-o"></i></th>
                  <th title="Перевод утро"><i class="fa fa-sun-o"></i><br /><i class="fa fa-headphones"></i></th>
                  <th title="Перевод вечер"><i class="fa fa-moon-o"></i><br /><i class="fa fa-headphones"></i></th>
                  <th title="Бейдж"><i class="fa fa-id-card"></i></th>
                  <th title="Итого" style="font-weight:bold">&sum;</th>
                  <th title="Было">
                    <i class="fa fa-money" style="color: yellowgreen"></i>
                  </th>
                  <th title="Оплочено">
                    <i class="fa fa-money" style="color: green"></i>
                  </th>
                  <th title="Долг">
                    <i class="fa fa-money" style="color: red"></i>
                  </th>
                  <th><i class="fa fa-users" title="Регистратор"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(reg, index) in regHistory" v-bind:key="reg.id">
                  <td>
                    <span
                      :title="reg.comment"
                      :class="{ hasComment: reg.comment }"
                      >{{ reg.id }}</span
                    >
                  </td>
                  <td :title="reg.dt">
                    {{ $moment(reg.dt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM') }}
                  </td>
                  <td>{{ reg.days || '-' }}</td>
                  <td>{{ reg.nights || '-' }}</td>
                  <td>
                    {{ reg.interpreters || '-' }}
                  </td>
                  <td>
                    {{ reg.interpreters2 || '-' }}
                  </td>
                  <td>{{ reg.bage || '-' }}</td>
                  <td>
                    {{ reg.stoim || '-' }}&nbsp;<i
                      class="fa calculate-history-log"
                      @click="toggleRegHistory(index)"
                      :class="reg.logVisible ? 'fa-eye' : 'fa-eye-slash'"
                      title="Детальнее"
                    ></i>
                    <div
                      v-if="regHistory[index].logVisible"
                      class="history-log-div"
                    >
                      <table
                        class="table"
                        v-if="reg.calculateLog && reg.calculateLog.length > 0"
                      >
                        <tr v-for="log in reg.calculateLog" :key="log.id">
                          <td v-html="log.name"></td>
                          <td v-html="log.amount"></td>
                        </tr>
                      </table>
                    </div>
                  </td>
                  <td>{{ reg.money_old || '-' }}</td>
                  <td>{{ reg.paid || '-' }}</td>
                  <td>{{ reg.borg || '-' }}</td>
                  <td>{{ reg.admin }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- class="payHistory" -->
      </div>
      <!-- class="main" -->
    </div>
    <add-comment
      v-if="isAddCommentActive"
      @close="closeComment"
      @save="closeComment"
      :initialValue="userData.comment"
      >{{ userData.comment }}
    </add-comment>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import addComment from '@/components/user/addComment';
const dayOfWeek = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота'
];
const monthes = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря'
];

export default {
  data: function() {
    return {
      userData: {},
      retreatData: {},
      admin: 0,
      poselenie: {},
      regHistory: [],
      bazas: {},
      uid: undefined,
      dirty: true,
      isArrived: false,
      dtArrivedConfig: {
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        locale: Russian,
        time_24hr: true,
        defaultHour: new Date().getHours(),
        defaultMinute: new Date().getMinutes()
      },
      isAddCommentActive: false,
      invoiceMode: false,
      total: null,
      paid: null,
      debt: null,
      totalDiscount: null,
      isCalculateLogVisible: false,
      calculateLog: '',
      invoiceBages: null
    };
  },

  methods: {
    async init() {
      this.$loading(true);
      const {
        body: { retreatData, userData, regHistory, bazas, invoiceBages }
      } = await this.getData(`/reg/${this.uid}`);
      console.log('userData', userData);
      console.log('retreatData', retreatData);
      console.log('regHistory', regHistory);
      console.log('bazas', bazas);

      this.retreatData = retreatData;
      this.bazas = bazas;

      userData.days = userData.days || '';
      userData.nights = userData.nights || '';
      this.poselenie = userData.poselenie || {};
      delete userData.poselenie;

      this.userData = userData;

      regHistory.forEach(item => {
        item.logVisible = false;
        item.calculateLog = JSON.parse(item.calculateLog);
        item.dt = this.$moment(item.dt).format('DD.MM.YYYY HH:mm:ss');
        return item;
      });
      this.regHistory = regHistory;
      this.isArrived = Boolean(this.userData.dt_arrived);
      this.dirty = true;
      this.isCalculateLogVisible = false;
      this.userData.interpreters = this.userData.interpreters || '';
      this.userData.interpreters2 = this.userData.interpreters2 || '';

      this.invoiceBages = invoiceBages;
      this.$loading(false);
    },
    addBage(event) {
      this.userData.bage_count += event.target.checked ? 1 : -1;
      this.dirty = true;
    },
    getRetreatDay: function(nn) {
      const stDay = new Date(this.retreatData.commandaStdate).getDate(); // число первого дня ретрита
      const theDate = new Date(
        new Date(this.retreatData.commandaStdate).setDate(stDay + nn - 1)
      ); // нужный день ретрита
      return dayOfWeek[theDate.getDay()];
    },
    getRetreatDate: function(nn) {
      const stDay = new Date(this.retreatData.commandaStdate).getDate(); // число первого дня ретрита
      const theDate = new Date(
        new Date(this.retreatData.commandaStdate).setDate(stDay + nn - 1)
      ); // нужный день ретрита
      return theDate.getDate() + ' ' + monthes[theDate.getMonth()];
    },
    canModify: function(day) {
      // проверим, можно ли изменять данные
      let stMofify = this.retreatData.curday - 1; // curday начинается с еденицы, у нас с нуля
      if (stMofify < 0) {
        stMofify = 0;
      }
      if (this.isPermited(3)) {
        // суперадминам можно всегда
        stMofify = 0;
      }
      return stMofify < day; // а вот нельзя, дата в прошлом
    },
    toggleInterpreter2: function(event) {
      const day = Number.parseInt(
        event.target.closest('td').getAttribute('data-id')
      );
      if (!this.canModify(day)) {
        return;
      }
      if (this.isInterpreterActive2(day)) {
        this.removeInterpreter2(day);
      } else {
        this.addInterpreter2(day);
      }

      this.$forceUpdate();
      this.dirty = true;
    },
    addInterpreter2(day) {
      const arr =
        this.userData.interpreters2.length === 0
          ? []
          : this.userData.interpreters2
              .split(/;/)
              .map(item => Number.parseInt(item));
      arr.push(day);
      this.userData.interpreters2 = arr.sort().join(';');
    },
    removeInterpreter2(day) {
      console.log('removeInterpreter2', day);
      const arr =
        this.userData.interpreters2.length === 0
          ? []
          : this.userData.interpreters2
              .split(/;/)
              .map(item => Number.parseInt(item));
      let i;
      arr.forEach((item, index) => {
        i = item === day ? index : i;
      });
      if (Number.isFinite(i)) {
        arr.splice(i, 1);
      }
      this.userData.interpreters2 = arr.sort().join(';');
    },
    toggleInterpreter: function(event) {
      const day = Number.parseInt(
        event.target.closest('td').getAttribute('data-id')
      );
      if (!this.canModify(day)) {
        return;
      }
      if (this.isInterpreterActive(day)) {
        this.removeInterpreter(day);
      } else {
        this.addInterpreter(day);
      }

      this.$forceUpdate();
      this.dirty = true;
    },
    addInterpreter(day) {
      const arr =
        this.userData.interpreters.length === 0
          ? []
          : this.userData.interpreters
            .split(/;/)
            .map(item => Number.parseInt(item));
      arr.push(day);
      this.userData.interpreters = arr.sort().join(';');
    },
    removeInterpreter(day) {
      console.log('removeInterpreter', day);
      const arr =
        this.userData.interpreters.length === 0
          ? []
          : this.userData.interpreters
            .split(/;/)
            .map(item => Number.parseInt(item));
      let i;
      arr.forEach((item, index) => {
        i = item === day ? index : i;
      });
      if (Number.isFinite(i)) {
        arr.splice(i, 1);
      }
      this.userData.interpreters = arr.sort().join(';');
    },
    toggleDay: function(event) {
      const day = Number.parseInt(event.target.getAttribute('data-id'));
      if (!this.canModify(day)) {
        return;
      }

      const kind = event.target.closest('tr').classList.contains('day')
        ? 'day'
        : 'night';

      if (kind === 'day') {
        let arr =
          this.userData.days.length === 0
            ? []
            : this.userData.days.split(/;/).map(item => Number.parseInt(item));
        if (this.isDayActive(day)) {
          let i;
          arr.forEach((item, index) => {
            i = item === day ? index : i;
          });
          if (Number.isFinite(i)) {
            arr.splice(i, 1);
          }
          this.removeInterpreter(day);
        } else {
          arr.push(day);
        }
        arr = arr.sort();
        this.userData.days = arr.join(';');
      } else {
        let arr =
          this.userData.nights.length === 0
            ? []
            : this.userData.nights
                .split(/;/)
                .map(item => Number.parseInt(item));

        if (this.isNightActive(day)) {
          let i;
          arr.forEach((item, index) => {
            i = item === day ? index : i;
          });
          if (Number.isFinite(i)) {
            arr.splice(i, 1);
          }
          delete this.poselenie[day];
          this.removeInterpreter2(day);
        } else {
          arr.push(day);
        }
        this.userData.nights = arr.sort().join(';');
      }
      this.dirty = true;
    },
    isInterpreterActive: function(day) {
      if (
        !this.userData.interpreters ||
        this.userData.interpreters.length === 0
      ) {
        return false;
      }
      return this.userData.interpreters
        .split(/;/)
        .map(item => Number.parseInt(item))
        .some(item => item === day);
    },
    isInterpreterActive2: function(day) {
      if (
        !this.userData.interpreters2 ||
        this.userData.interpreters2.length === 0
      ) {
        return false;
      }
      return this.userData.interpreters2
        .split(/;/)
        .map(item => Number.parseInt(item))
        .some(item => item === day);
    },
    isDayActive: function(day) {
      if (!this.userData.days || this.userData.days.length === 0) {
        return false;
      }
      return this.userData.days
        .split(/;/)
        .map(item => Number.parseInt(item))
        .some(item => item === day);
    },
    isNightActive: function(day) {
      if (!this.userData.nights || this.userData.nights.length === 0) {
        return false;
      }
      return this.userData.nights
        .split(/;/)
        .map(item => Number.parseInt(item))
        .some(item => item === day);
    },
    doPoselenie: function(event) {
      console.info('doPoselenie');
      const baza = Number.parseInt(event.target.getAttribute('data-baza'));
      const night = Number.parseInt(event.target.getAttribute('data-night'));
      if (!this.canModify(night)) {
        return;
      }
      this.poselenie[night] = baza;
      this.dirty = true;
      this.$forceUpdate();
    },
    fullAmount: function() {
      this.userData.paid = this.debt;
      this.$forceUpdate();
    },
    processRegisteration: async function() {
      this.$loading(true);
      const postData = {
        dt_arrived: this.userData.dt_arrived
          ? this.$moment(this.userData.dt_arrived, 'DD.MM.YYYY HH:mm').format(
              'YYYY-MM-DDTHH:mm'
            )
          : null,
        paid: this.userData.paid,
        discount: this.userData.discount,
        bage_count: this.userData.bage_count,
        days: this.userData.days,
        nights: this.userData.nights,
        interpreters: this.userData.interpreters,
        interpreters2: this.userData.interpreters2,
        poselenie: this.poselenie,
        comment: this.userData.comment,
        createInvoice: this.invoiceMode
      };
      if (this.isArrived) {
        delete postData.dt_arrived;
      }
      console.log(JSON.stringify(postData));
      if (this.isArrived) {
        delete postData.dt_arrived;
      }

      try {
        const { body } = await this.postData(`/reg/${this.uid}`, postData);
        console.log(body);
        this.$loading(false);
        this.$router.push('/');
      } catch (e) {
        this.$loading(false);
        console.error(e);
      }
    },
    addComment() {
      this.isAddCommentActive = true;
    },
    closeComment(comment) {
      if (comment) {
        this.userData.comment = comment;
      }
      this.isAddCommentActive = false;
    },
    async calculate() {
      const postData = {
        discount: Number.parseInt(this.userData.discount),
        bage_count: this.userData.bage_count,
        days: this.userData.days,
        nights: this.userData.nights,
        interpreters: this.userData.interpreters,
        interpreters2: this.userData.interpreters2,
        poselenie: this.poselenie
      };
      console.log(postData);
      const { body } = await this.postData(`/calculate/${this.uid}`, postData);
      console.log('calculate', body);
      this.total = body.total;
      this.paid = body.paid;
      this.totalDiscount = body.totalDiscount;
      this.debt = body.debt;
      this.dirty = false;
      this.calculateLog = body.stoimDescription.filter(
        item => item.name !== 'Платеж'
      );
    },
    toggleRegHistory(index) {
      let curValue = this.regHistory[index].logVisible;
      if (!curValue) {
        this.regHistory
          .filter((item, index1) => index1 !== index)
          .forEach(item => (item.logVisible = false));
      }

      this.regHistory[index].logVisible = !curValue;
    },
    toggleCalculatedLog() {
      event.stopPropagation();
      this.isCalculateLogVisible = !this.isCalculateLogVisible;
    },
    rowClick() {
      if (this.isCalculateLogVisible) {
        this.isCalculateLogVisible = false;
      }
    }
  },
  mounted() {
    this.uid = Number.parseInt(this.$route.params.uid);
    this.admin = this.$session.get('id');
    this.init();
  },
  components: {
    flatPickr,
    addComment
  }
};
</script>

<style lang="scss" scoped>
@import '../variables';
@import '~bootstrap/scss/bootstrap.scss';

.maindiv {
  @extend .text-center;
  @extend .col-xl-6;
  @extend .offset-xl-3;
  @extend .col-lg-8;
  @extend .offset-lg-2;
  @extend .col-md-12;
  @extend .offset-md-0;
}

div.left {
  text-align: left;
  display: table-cell;
  vertical-align: top;
  padding: 5px 5px 0 15px;

  & .card {
    width: 180px;
  }

  & .card-body {
    padding: 0.4rem;
    font-size: 0.8rem;
  }

  & .form-control-ssm {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    line-height: 1.2;
    height: 24px;
  }

  & .form-group {
    height: 16px;
    margin-bottom: 0.8rem;
  }
}

div.main {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  padding: 5px;
  // @extend .float-left;

  & .userFIO {
    @extend .col-xl-7,
      .offset-xl-3,
      .col-lg-8,
      .offset-lg-2,
      .col-md-10,
      .offset-md-1,
      .col-sm-10,
      .offset-sm-1;
    font-size: 18px;
    text-decoration: none;

    & .fa {
      padding-right: 5px;
    }
  }

  & div.daysAndNights {
    & table {
      text-align: center;
      font-size: 12px;
      border: 1px black solid;

      td {
        width: 90px;
        height: 90px;
        border: 1px dotted black;
        cursor: pointer;
      }
    }
  }

  & .label {
    color: #999;
    font-size: 14px;
    padding: 0;
    display: inline-block;
    text-transform: uppercase;
  }

  & .addInfo {
    color: #0000aa;
    font-size: 12px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & .active {
    background-color: $active !important;
    & .fa-headphones {
      display: block;
    }
  }

  & .baza {
    font-size: 0.6rem;
    text-align: left;
  }

  & .baza-item {
    display: block;
  }

  & .baza-item:hover {
    background-color: $hovered;
    cursor: pointer;
  }

  & .dt-arrived {
    background-color: #eee;
    border: 1px #777 dotted;
    border-radius: 3px;
    padding: 3px;
  }

  & .clear-dt-arrived {
    position: absolute;
    margin-left: -20px;
    cursor: pointer;
    font-size: 0.8rem;
    margin-top: 4px;
    color: #777;
  }
}

.hasComment {
  background-color: yellow;
  padding: 5px;
  cursor: context-menu;
  font-weight: bold;
}

.history {
  @extend .card;
  margin-top: 10px;
  @extend .d-xl-block, .d-lg-block, .d-md-none, .d-sm-none;
}

.day td, .night td {
  position: relative;
}

.daily-interpreter-mark {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #ced4da;
  display: none;
}

.daily-interpreter-mark.active {
  border: 1px #000 dotted;
  padding: 1px;
  border-radius: 2px;
  color: #00f;
}

.daily-interpreter-mark:hover {
  font-size: 1.3em;
  color: #000;
}

.calculate-log-visiblity-control {
  display: block;
  position: absolute;
  z-index: 9999;
  top: 5px;
  right: 25px;
  cursor: pointer;
}

.calculate-log {
  display: block;
  position: absolute;
  z-index: 9999;
  background-color: beige;
  border: 2px #444 dotted;
  width: 500px;
  /*height: 600px;*/
  overflow: scroll;
  color: #000;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}

.red {
  color: red;
}

.invisible-grey {
  color: #e9ecef;
}

.calculate-history-log {
  cursor: pointer;
}

.history-log-div table.table {
  font-size: 0.75em;
  position: absolute;
  display: block;
  width: 230px;
  height: 235px;
  background-color: beige;
  border: 2px #444 dotted;
  border-radius: 5px;
  text-align: left;
  overflow: scroll;
}
</style>
