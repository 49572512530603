<template>
    <div>
        <div>
            <label class="label">Комментарии</label>
            <add-item @click="addComment" style="left: 135px;top: -3px"></add-item>

            <table class="table less">
                <thead>
                <th width="100px">Дата</th>
                <th>Рег.</th>
                <th>Текст</th>
                <th></th>
                </thead>
                <tbody>
                <tr
                        v-for="comment in comments"
                        :key="comment.id"
                >
                    <td>{{comment.dt}}</td>
                    <td :title="comment.fullname">{{comment.name}}</td>
                    <td>
                        <pre>{{comment.comment}}</pre>
                    </td>
                    <td>
                        <a
                                :data-id="comment.id"
                                href=""
                                class="delete"
                                @click.prevent="deleteComment"
                        >x</a>
                    </td>
                </tr>
                </tbody>

            </table>
        </div>
        <add-comment v-if="mode === 'add'" @close="closeAddComment" @save="saveComment"></add-comment>
    </div>

</template>

<script>
  import AddComment from './addComment';
  import AddItem from '../AddItem';

  export default {
    data: function () {
      return {
        comments: [],
        mode: 'view'
      }
    },
    props: {
      uid: {
        type: Number,
        required: true
      }
    },
    methods: {
      addComment() {
        this.mode = 'add';
      },
      closeAddComment() {
        this.mode = 'view';
      },
      async saveComment(text) {
        await this.postData(`/users/${this.uid}/comments`, { comment: text });
        this.closeAddComment();
        this.init();
      },
      async deleteComment(event) {
        const id = event.target.getAttribute('data-id');
        await this.deleteData(`/users/comments/${id}`);
        this.init();
      },
      async init() {
        try {
          const { body } = await this.getData(`/users/${this.uid}/comments`);
          body.forEach(item => {
            item.dt = this.$moment(item.dt).format('DD.MM.YYYY HH:mm:ss');
          });
          this.comments = body;
        } catch (e) {
          console.error(e);
        }
      }
    },
    mounted() {
      this.init();
    },
    components: {
      AddComment,
      AddItem
    }
  }
</script>

<style scoped>
    a.delete {
        color: red;
    }

</style>
