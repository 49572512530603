<template>
    <div style="margin-left: 15px">
        <div v-if="isPermited(2)" class="row form-group">
            <div class="fullname">
                <autocomplete
                        ref="fio"
                        placeholder="Ф.И.О."
                        :source="getByFIO"
                        input-class="form-control"
                        :request-headers="authHeaders"
                        @selected="fioSelected"
                        :results-display="formattedDisplay"
                        :showNoResults="false"
                        resultsProperty="fullname"
                        resultsValue="uid"
                ></autocomplete>
            </div>
            <div class="img-btn " >
                <img
                    v-if="isPermited(8)"
                    src="@/assets/add_user_male_40-40.png"
                    title="Новый служитель"
                    @click="$emit('new')"
                />
                <img
                        src="@/assets/find_matching_candidate-40.png"
                        title="Расширенный поиск"
                        @click="$emit('advancedSearch')"
                        style="padding-left: 3px"
                />
            </div>

        </div>
        <div v-if="isPermited(1)" class="row ">
            <div class="uid">
                <input
                        ref="uid"
                        class="form-control"
                        type="number"
                        v-model="uid"
                        placeholder="UID"
                        @keydown="uidKeyDown"
                />
            </div>

        </div>
    </div>

</template>

<script>
  import Autocomplete from 'vuejs-auto-complete';

  export default {
    data: function () {
      return {
        uid: undefined,
        partFullname: ''
      }
    },
    computed: {
      authHeaders() {
        return {
          'Authorization': `Bearer ${this.$session.get('token')}`
        }
      }
    },
    components: {
      Autocomplete
    },
    methods: {
      getByFIO(name) {
        this.partFullname = name;
        this.$emit('partFullname', name);
        return `${process.env.VUE_APP_API}/findUser?fullname=${name}`;
      },
      fioSelected(obj) {
        this.uid = obj.value;
        this.getUser();
      },
      formattedDisplay(result) {
        return `${result.fullname} | ${this.$moment(result.birthday).format('DD.MM.YYYY')}`;
      },
      getUser() {
        this.$emit('onUID', this.uid);
      },
      uidKeyDown(event) {
        switch (event.key) {
          case 'Enter':
            this.getUser();
            break;
          case 'Escape':
            this.uid = undefined;
            break;
          default:
            this.status = 'search';
            break;
        }
      }

    },
    mounted() {
      if (this.$refs.uid) {
        this.$refs.uid.focus();
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import '~bootstrap/scss/bootstrap.scss';

    .fullname{
        @extend .col-10;
        @extend .col-sm-9;
        @extend .col-md-8;
        @extend .col-lg-6;
        @extend .col-xl-5;
        margin-top: -5px;
        @extend .form-group;
    }
    .img-btn{
        img{
            padding: 0;
            width: 25px;
            margin: 0 2px 0 0;
            border: 0;
            cursor: pointer;
            @media (min-width: 768px) {
                width: 30px;
            }
            @media (min-width: 1200px) {
                width: 40px;
            }
        }
    }
    .uid{
        @extend .col-6;
        @extend .col-sm-5;
        @extend .col-md-4;
        @extend .col-lg-3;
        @extend .col-xl-2;
        @extend .form-group;
    }

</style>
