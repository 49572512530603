<template>
    <div class="invoices">
        <h5>История посещений</h5>
        <table class="table table-bordered">
            <tr>
                <td>Ретрит</td>
                <td>Дни</td>
                <td>Ночи</td>
            </tr>
            <tr v-for="item in history"
                :key="item.id"
            >
                <td>{{item.retreat}}</td>
                <td>{{item.days}}</td>
                <td>{{item.nights}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
  export default {
    name: 'userHistory',
    props: {
      uid: {
        type: Number,
        required: true
      }
    },
    data: function () {
        return {
          history: []

        }
    },
    async mounted() {
      const { body } = await this.getData(`/users/${this.uid}/history`);
      this.history = body;
    }
  }
</script>

<style scoped lang="scss">

</style>
