<template>
    <div>
        <add-item v-if="canAdd && editable"  @click="addConfirm" style="right: 25px; top: 23px"></add-item>
        <autocomplete
                v-if="editable"
                ref="item"
                :placeholder="placeholder"
                :source="getItems"
                :input-class="getInputClass()"
                :request-headers="authHeaders"
                @selected="selected"
                @clear="clearItem"
                :initialValue="initialValue"
                :initialDisplay="initialDisplay"
                :results-display="formattedDisplay"
                :showNoResults="false"
                resultsProperty="uid"
                resultsValue="uid"
                :disableInput="disabled"
        >
        </autocomplete>
        <div v-if="!editable" class="edit" @click="edit">{{initialDisplay}}</div>
        <app-modal v-if="confirmation" @close="closeConfirm" @confirm="confirm">
            <div>Добавить {{name}} <b>{{itemPart}}</b>?</div>

        </app-modal>
    </div>

</template>

<script>
  import Autocomplete from 'vuejs-auto-complete';
  import appModal from './modal';
  import AddItem from '../AddItem';

  export default {
    data: function () {
      return {
        editable: false,
        confirmation: false,
        itemPart: this.initialDisplay
      }
    },
    props: {
      url: {
        type: [String, Function],
        required: true
      },
      placeholder: {
        type: String,
        default: 'Начинайте вводить для поиска'
      },
      source: {
        type: [Function, Object]
      },
      inputClass: {
        type: String,
        default: 'form-control'
      },
      initialValue: {
        type: [String, Number]
      },
      initialDisplay: {
        type: String
      },
      canAdd: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: 'элемент'
      },
      canClear: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      getInputClass() {
        if (this.disabled) {
          const arr = this.inputClass.split(/\s/);
          arr.push('disabled');
          return arr.join(' ');
        }
        return 'form-control disable';
      },
      edit() {
        this.editable = true;
      },
      clearItem() {
        this.$refs.item.$el.classList.remove('ok');
        this.$emit('clear');
      },
      selected(obj) {
        this.$refs.item.$el.classList.add('ok');
        this.$emit('selected', obj);
        this.editable = false;
      },
      getItems(name) {
        this.itemPart = name;
        return `${process.env.VUE_APP_API}/${this.url}${name}`;
      },
      formattedDisplay(result) {
        this.$emit('change', result.name);
        return result.name;
      },
      addConfirm() {
        this.confirmation = true;
      },
      closeConfirm() {
        this.confirmation = false;
      },
      confirm() {
        this.$emit('addItem', this.itemPart);
        this.confirmation = false;
        this.editable = false;
      }
    },
    computed: {
      authHeaders() {
        return {
          'Authorization': `Bearer ${this.$session.get('token')}`
        }
      }
    },
    components: {
      Autocomplete,
      appModal,
      AddItem
    },
    mounted() {
      this.editable = !this.initialDisplay || this.initialDisplay.length === 0;
    },
    watch: {
      initialDisplay: function (newValue) {
        if (!newValue) {
          this.editable = true;
        }
      }
    }
  }

</script>

<style scoped>
    .lighted {
        color: red;
        font-weight: bold;
    }

    .autocomplete.ok {
        border: 3px #dfd solid;
    }

    .edit {
        cursor: pointer;
        text-decoration: underline;
        color: #77f;
        background-color: #eee;
        border: 1px #ccc dotted;
        border-radius: 5px;
        padding: 8px;
        font-size: 16px;
    }

</style>
<style>
    .disabled, .disabled input{
        background-color: #eee;
    }
</style>
