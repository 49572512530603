<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container card">

                    <div class="modal-header label">Добавление комментария</div>

                    <div class="modal-body">
                        <textarea
                                class="form-control"
                                ref="textarea"
                                style="height: 200px"
                                v-model.trim="text"
                        >
                        </textarea>
                    </div>

                    <div class="row" style="padding: 20px">
                        <button class="btn btn-secondary col-md-4 offset-md-1" @click="$emit('close')">Отмена</button>
                        <button class="btn btn-success col-md-4 offset-md-2" @click="save">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    data: function () {
      return {
        text: ''
      }
    },
    props: {
      initialValue: {
        required: false,
        default: ''
      }
    },
    mounted() {
      this.$refs.textarea.focus();
      if (this.initialValue) {
        this.text = this.initialValue;
      }
    },
    methods: {
      save() {
        this.$emit('save', this.text);
      }
    }
  }

</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 600px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header {
        margin-top: 0;
    }

    .modal-body {
        margin: 10px 0;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>
