<template>
    <div class="row">
        <div class="col-md-3 offset-md-1">
            <label class="label">Дата начала</label>
            <flat-pickr
                    v-model="from"
                    class="input form-control form-control-sm"
                    :config="dateTimePicker"
            ></flat-pickr>
        </div>
        <div class="col-md-3 offset-md-1">
            <label class="label">Дата окончания</label>
            <flat-pickr
                    v-model="to"
                    class="input form-control form-control-sm"
                    :config="dateTimePicker"
            ></flat-pickr>
        </div>
        <div class="col-md-2 offset-md-1" style="padding-top: 25px">
            <button type="submit" class="btn btn-primary" @click="fetchData">Показать</button>
        </div>
        <div class="col-md-10 offset-md-1 form-group" style="padding-top: 25px">
            <input type="checkbox" id="onlyPaid" v-model="onlyPaid">
            &nbsp;
            <label for="onlyPaid" class="label">Только платежи</label>
        </div>
        <div class="col-md-10 offset-md-1" style="margin-top: 20px" >
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">№№</th>
                    <th scope="col">ID</th>
                    <th scope="col">UID</th>
                    <th scope="col">Ф.И.О.</th>
                    <th scope="col">дата</th>
                    <th scope="col">Сумма</th>
                    <th scope="col">Прибытие</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in smartData"
                    v-bind:data-uid="item.uid"
                    v-bind:key="item.id"
                    class="hover"
                >
                    <td>{{index+1}}</td>
                    <td><router-link :to="'/reg/'+item.uid" target="_blank">{{item.id}}</router-link></td>
                    <td><router-link :to="'/user/'+item.uid">{{item.uid}}</router-link></td>
                    <td>{{item.fullname}}</td>
                    <td>{{$moment(item.dt).format('DD.MM.YYYY HH:mm:ss')}}</td>
                    <td>{{item.paid}}</td>
                    <td>{{item.dt_arrived?$moment(item.dt_arrived).format('DD.MM.YYYY HH:mm:ss'):'-'}}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <th colspan="5">Итого, грн.</th>
                    <th>{{allPaid}}</th>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import { Russian } from 'flatpickr/dist/l10n/ru.js';

  export default {
    name: 'registered',
    data: function () {
      return {
        data: [],
        from: new Date(),
        to: new Date(),
        dateTimePicker: {
          enableTime: false,
          locale: Russian,
          time_24hr: true
        },
        onlyPaid: false
      }
    },
    computed: {
      allPaid: function () {
        return this.data.reduce((sum, item) => sum + Number.parseFloat(item.paid), 0);
      },
      smartData: function() {
        if (!this.onlyPaid) {
          return this.data;
        }
        return this.data.filter(item => item.paid !== 0)
      }
    },
    components: {
      flatPickr
    },
    methods: {
      async fetchData() {
        this.$loading(true);
        const from = this.$moment(this.from).format('YYYY-MM-DD 00:00:00');
        const to = this.$moment(this.to).format('YYYY-MM-DD 23:59:59');
        try {
          // console.log(`/registered?from=${from}&to=${to}`);
          const { body } = await this.getData(`/registered?from=${from}&to=${to}`);
          // console.log(body);
          this.data = body;
        } catch (e) {
          console.error(e);
        }

        this.$loading(false);
      },
      go(event) {
        const uid = event.target.closest('tr').getAttribute('data-uid');
        const win = window.open(`/reg/${uid}`, '_blank');
        win.focus();
      }
    }
  }
</script>
